import {request_async} from "@/utils/requestAsync";
import {API_URL_ERP} from "@/model/ConfigModel";

// todo 改用exp提供接口，或生成统一token验证，保证安全性

// 获取列表-分页
export async function getPageList(platformId, page, size, userUnionId, data) {
  return request_async(API_URL_ERP + `v1/message/systemMessage/exp/list?platformId=${platformId}&page=${page}&size=${size}&userUnionId=${userUnionId}`, "post_body", data);
}


// 设置消息已读
export async function setMessageRead(messageId,userUnionId) {
  return request_async(API_URL_ERP + `v1/message/systemMessage/read?userUnionId=${userUnionId}&messageId=${messageId}`, "post_json", {});
}