import {date_format} from "@/utils/common";

// 日期格式化
const dateFormat = (value, format) => {
    if (value) {
        let formatString = "yyyy/MM/dd HH:mm"
        if (format) {
            formatString = format
        }
        return date_format(value, formatString)
    }
    return ""
}
// 数字格式化
const numberFormat = (value, num) => {
    if (value||value===0) {
        let number = 0
        if (num) {
            number = num
        }
        return value.toFixed(number)
    }
    return ""
}

function lessThan10By0(number) {
    if (number < 10) {
        number = "0" + number;
    }
    return number;
}

// 分钟和秒数格式化 传入毫秒
const minuteSecondFilter=function (value) {
    value=Math.round(value/1000);
    let sec=0;
    let min=0;
    if (value < 60) {
        sec=Number(value).toFixed(0)
    } else {
        min = Math.floor(value / 60)
        sec = Math.floor(value - min * 60)
    }
    return `${lessThan10By0(min)}:${lessThan10By0(sec)}`
}

export {dateFormat, numberFormat,minuteSecondFilter}