import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_ADMIN} from "@/model/ConfigModel";

// 获取实验须知
export async function getKnowConfig() {
    return request_async(API_URL_EXP_ADMIN + "/admin/expawareness/info", "get", {});
}

// todo-判断实验须知是否已读
export async function judgeKnowRead() {
    return request_async(API_URL_EXP_ADMIN + "/admin/expawareness/JudgeRead", "get", {})
}

// 设置实验须知已读
export async function setRead(data) {
    return request_async(API_URL_EXP_ADMIN + "/admin/expawareness/update", "post_body", data);
}