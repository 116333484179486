import {CommonModel} from "@/model/CommonModel";
import {getPageList,setMessageRead} from "@/api/erp/MessageApi";

/**
 * 消息model
 */
class MessageModel {
    // 获取列表
    static async getPageList(platformId, page, size, userUnionId, document) {
        let [data] = await getPageList(platformId, page, size, userUnionId, document);
        return [CommonModel.generateListMongo(data.data),data.data.notReadNum];
    }

    // 设置消息已读
    static async setMessageRead(messageId,userUnionId) {
        let [res] = await setMessageRead(messageId,userUnionId)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }
}


export {MessageModel}
